.header {
    border-top: solid #e8e8e6 0.25vw;
    border-bottom: solid #e8e8e6 0.25vw;

    margin-top: 4vw;
}

.navBar {
    text-align: right;
}

.tab {
    background-color: #0f0f11;
    border: none;

    font-family: "FutureTech";
    font-size: 3vw;
    color: #e8e8e6;
}