.blurbHeader {
    font-family: "FutureTech";
    font-weight: 600;
    font-size: 6.5vw;
    color: #0139f5;

    margin-bottom: 0;
    margin-top: 0;
}

.blurbSubHeader {
    position: absolute;

    font-family: "FutureTech";
    font-weight: 600;
    font-size: 6.2vw;
    color: #0f0f11;

    margin-bottom: 0;
    margin-top: 0.75vw;
    margin-left: 24.2vw;
}

.subHeaderMoney {
    font-size: 13.5vw;
    text-shadow: 1vw 1vw #defa2d;

    margin-top: 8.25vw;
    margin-left: 30vw;
}

.subHeaderBottom {
    width: 60vw;
    text-align: center;
    font-size: 5.5vw;

    margin-top: 20.5vw;
    margin-left: 26.5vw;
}

.upperText {
    margin-left: 16vw;
    padding-top: 4vw;
}

.lowerText {
    font-size: 6.75vw;

    margin-top: 1vw;
    margin-left: 4vw;
}

.blurbParts {
    position: absolute;

    z-index: -1;
}

.blueBall {
    position: absolute;
    width: 8vw;
    height: 8vw;

    border-radius: 5vw;
    background-color: #0139f5;

    z-index: 6;
}

.blueBallLeft {
    margin-left: 7.5vw;
    margin-top: 0.25vw;
}

.blueBallRight {
    margin-left: 88vw;
}

.right1 {
    margin-top: 27vw;
}

.right2 {
    margin-top: 34vw;
}

.right3 {
    margin-top: 41vw;
}

.blurb {
    position: absolute;

    background-color: #e8e8e6;
}

.overlapBlurb {
    position: absolute;
    height: 4vw;
    width: 4vw;

    background-color: #0f0f11;

    z-index: 5;
}

.upperHeaderBlurb {
    height: 23vw;
    width: 80vw;

    border-top-left-radius: 5vw;
    border-bottom-right-radius: 5vw;
    border-top-right-radius: 5vw;

    margin-left: 18vw;
    margin-top: 2vw;
}

.lowerHeaderBlurb {
    height: 20vw;
    width: 80vw;

    border-bottom-left-radius: 5vw;
    border-bottom-right-radius: 5vw;

    margin-left: 5vw;
    margin-top: 30vw;
}

.blurbBody {
    height: 20vw;
    width: 80vw;

    border-top-left-radius: 5vw;

    margin-left: 5vw;
    margin-top: 10vw;
}

.leftRectangle {
    width: 4vw;
    height: 4vw;

    margin-left: 14vw;
    margin-top: 6vw;

    z-index: 3;
}

.rightRectangle {
    width: 4vw;
    height: 4vw;

    margin-left: 85vw;
    margin-top: 25vw;

    z-index: 3;
}

.upperOverlap {
    border-bottom-right-radius: 2vw;

    margin-left: 14vw;
    margin-top: 6vw;
}

.lowerOverlap {
    border-top-left-radius: 2vw;

    margin-left: 85vw;
    margin-top: 25vw;
}

.star {
    position: absolute;
}

.starImg {
    position: absolute;

    width: 30vw;
    height: 35vw;

    z-index: 2;
}

.starText {
    position: absolute;

    color: black;
    font-family: "FutureTech";
    font-weight: 600;
    font-size: 3.5vw;

    margin-left: 9vw;
    margin-top: 10.5vw;

    z-index: 4;
}

.secondRow {
    width: 20vw;

    font-size: 6.5vw;
    text-align: left;

    margin-top: 14.5vw;
    margin-left: 9.5vw;
}

.thirdRow {
    text-align: left;

    margin-top: 20vw;
    margin-left: 9.5vw;
    
}