.Logo {
    width: 90%;
    align-content: center;
  
    margin-top: 2vh;
    margin-bottom: 0vh;
}

.subheaderText {
    font-family: "FutureTech";
    font-size: 8.5vw;
    color: #cce240;
  
    margin-top: 0vw;
    margin-bottom: 2vw;
  }
  
  .waterBall {
    position: absolute;
    top: 11vw;
  
    width: 10vw;
  }
  
  .waterLeft {
    left: 3vw;
  }
  
  .waterRight {
    left: 88vw;

    transform: rotate(180deg);
  }
  